import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Leaf1, Leaf2, about } from "../assets";
import { SkillCard } from "../components";

const Skills = () => {
  return (
    <section
      id="skills"
      className="flex items-center justify-center flex-col gap-12 my-12"
    >
      <div className="w-full flex item-center justify-center py-24">
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: 200 }}
          exit={{ opacity: 0, width: 0 }}
          transition={{ delay: 0.4 }}
          className="flex items-center justify-around w-52"
        >
          <img src={Leaf1} className="w-6 h-auto object-contain" alt="" />
          <p className="text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
            Skills
          </p>
          <img src={Leaf2} className="w-6 h-auto object-contain" alt="" />
        </motion.div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full ">
        <div className="w-full px-8 flex flex-col gap4 items-start justify-start  ">
          <p className="text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
            My Skills and Work
          </p>
          <p className=" text-texlight text-base tracking-wide text-justify">

          In my journey so far, I've explored various aspects of technology, focusing on AI chatbot development and data analytics. I've worked on creating intelligent conversational agents, developing Discord bots that engage users in real-time conversations and improve user interaction.          </p>
          <br />

          <p className=" text-texlight text-base tracking-wide text-justify">
          Apart from chatbot development, I've also worked on significant projects in data analytics and visualization. By analyzing complex datasets and using innovative visualization techniques, I've been able to derive valuable insights and present them in a compelling manner, aiding in better decision-making processes.          </p>
          <br />

          <p className=" text-texlight text-base tracking-wide text-justify">
          Additionally, I've pursued my creative interests by delving into digital artistry. Using Wallpaper Engine, I've designed captivating live wallpapers that blend artistic expression with technological innovation, drawing inspiration from diverse themes to create immersive visual experiences.          </p>
          <br />

          <p className=" text-texlight text-base tracking-wide text-justify">
          Furthermore, I've made contributions to two notable MERN stack projects: a dynamic job portal for efficient job seeking and posting, and an interactive online quiz platform for engaging quizzes. These experiences reflect my dedication to pushing the boundaries of technology and making impactful contributions to the digital realm.          </p>
          <br />
        </div>
        <div className="flex flex-wrap gap-4 items-center justify-center py-50">
          <SkillCard skills={"Python"} />
          <SkillCard skills={"HTML 5"} />
          <SkillCard skills={"CSS "} />
          <SkillCard skills={"Wallpaper Engine"} />
          <SkillCard skills={"MongoDB"} />
          <SkillCard skills={"Node.js"} />
          <SkillCard skills={"React"} />
          <SkillCard skills={"Express"} />
          <SkillCard skills={"JavaScript"} />
        </div>
      </div>
    </section>
  );
};

export default Skills;
