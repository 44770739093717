import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Hero } from "../assets";
import { HeroTypeWritter, HomeSocialLinks } from "../components";
import { Socials } from "../utils/helper";

const Home = () => {
  return (
    <section
      id="home"
      className="flex items-center justify-center flex-col gap-12 relative"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full ">
        <div className="w-full h-full flex flex-col items-center lg:items-start justify-center gap-4">
          <h2 className="text-3xl lg:text-4xl text-texlight">
            Hello, It's me
            <span className="block tracking-wider text-4xl lg:text-6xl mt-4 text-white">
              {" "}
              Avinash
            </span>
          </h2>
          <h2 className="text-2xl lg:text-4xl text-texlight mt-4">
            And I'm{" "}
            <HeroTypeWritter
              speed={100}
              words={[
                "a Developer...",
                "a Student...",
                "a Freelancer...",
                "a Gamer...",
                "a Moviegoer...",
              ]}
            />
          </h2>
          <p className="text-base text-texlight mt-6 text-center lg:text-left">
            I'm passionate about gaming, crafting eye-catching Live wallpapers,
            and dabbling in a bit of coding on the side. Whether I'm
            strategizing my way through virtual worlds, designing eye-catching
            digital art, or tinkering with code, I'm always exploring new
            frontiers in the digital realm. Join me on this exciting journey,
            and let's bring our ideas to life together!
          </p>
          <div className="flex item-center justify-center gap-16 mt16">
            <AnimatePresence>
              {Socials &&
                Socials.map((item, index) => (
                  <HomeSocialLinks key={index} data={item} index={index} />
                ))}
            </AnimatePresence>
          </div>
        </div>
        <div className="w-full h-full flex items-start justify-center lg:item-center">
          <motion.img
            initial={{ y: 0 }}
            animate={{ y: [-10, 10, -10] }}
            transition={{
              repeat: Infinity,
              duration: 4,
              ease: "linear",
            }}
            src={Hero}
            className="w-auto h-auto object-contain"
          />
        </div>
      </div>
    </section>
  );
};

export default Home;
