import React from "react";
import {
  About,
  Contact,
  Header,
  Home,
  ParticlesContainer,
  Projects,
  ServiceCount,
  Skills,
} from "./";
import { AnimatePresence } from "framer-motion";
import { Socials } from "../utils/helper";
import { HomeSocialLinks } from "../components";

const App = () => {
  return (
    <div className="w-full xl:w-[1600px] py-32 px-4 lg:px-12 pr-4 lg:pr-32">
      <ParticlesContainer />
      <Header />
      <Home />
      <ServiceCount />
      <Skills />
      <Projects />
      <Contact />
      <div className="w-full flex flex-col items-center justify-start mt-32 mb-12">
        <p className="text 3xl tracking-wide text-texlight">
          Warale Avinash Kalyan
        </p>
        <div className="flex items-center justify-center gap-16 mt-16  ">
          <AnimatePresence>
            {Socials &&
              Socials.map((item, index) => (
                <HomeSocialLinks key={index} data={item} index={index} />
              ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default App;
