import {
  FaDiagramProject,
  FaEnvelope,
  FaInstagram,
  FaGithub,
  FaHouse,
  FaLinkedinIn,
  FaRenren,
  FaUser,
  FaDiscord,
  FaXTwitter,
  FaSteam,
} from "react-icons/fa6";
import { ChatPy, jobgramm, Loki, Omen, ARP, quizgramm } from "../assets";

export const Socials = [
  {
    id: `linkedin-${Date.now()}`,
    Icon: FaLinkedinIn,
    uri: "https://www.linkedin.com/in/avinash-warale/",
    color: "#0072b1",
  },
  {
    id: `github-${Date.now()}`,
    Icon: FaGithub,
    uri: "https://github.com/p4rz1v4l26",
    color: "#fff",
  },
  {
    id: `instagram-${Date.now()}`,
    Icon: FaInstagram,
    uri: "https://www.instagram.com/26__avinash/",
    color: "#F56040",
  },

  {
    id: `discord-${Date.now()}`,
    Icon: FaDiscord,
    uri: "https://discordapp.com/users/896411007797325824/",
    color: "##7289da",
  },
  {
    id: `x-${Date.now()}`,
    Icon: FaXTwitter,
    uri: "https://twitter.com/avinashwarale",
    color: "##FFFFFF",
  },
];

export const Menus = [
  {
    id: `home-${Date.now()}`,
    Icon: FaHouse,
    uri: "#home",
    name: "Home",
  },
  {
    id: `skills-${Date.now()}`,
    Icon: FaRenren,
    uri: "#skills",
    name: "Skills",
  },
  {
    id: `projects-${Date.now()}`,
    Icon: FaDiagramProject,
    uri: "#projects",
    name: "Projects",
  },
  {
    id: `contact-${Date.now()}`,
    Icon: FaEnvelope,
    uri: "#contact",
    name: "Contact",
  },
];

export const ProjectsData = [
  {
    id: `jobgramm-${Date.now()}`,
    name: "Job Portal using MERN",
    imgSrc: jobgramm,
    gitURL: "https://jobgramm.netlify.app/",
  },
  {
    id: `quizgramm-${Date.now()}`,
    name: "Online quiz maker using MERN",
    imgSrc: quizgramm,
    gitURL: "https://github.com/p4rz1v4l26/QuizGramm_local",
  },
  {
    id: `ARP-${Date.now()}`,
    name: "App Rating Prediction",
    imgSrc: ARP,
    gitURL: "https://github.com/p4rz1v4l26/App_Rating_Project",
  },
  {
    id: `Omen-${Date.now()}`,
    name: "OmenWE",
    imgSrc: Omen,
    gitURL: "https://steamcommunity.com/sharedfiles/filedetails/?id=3135113027",
  },
  {
    id: `chatbot_py-${Date.now()}`,
    name: "AI ChatBot using py",
    imgSrc: ChatPy,
    gitURL: "https://discord.gg/q9CwW9T7AC",
  },
  {
    id: `Loki-${Date.now()}`,
    name: "LokiWE",
    imgSrc: Loki,
    gitURL: "https://steamcommunity.com/sharedfiles/filedetails/?id=3080538007",
  },
];
