import React from "react";
import { ServiceCard } from "../components";

const ServiceCount = () => {
  return (
    <div className="wfull py6 lg:py24 mt-24 flex items-center justify-center flex-wrap gap-8 ">
      <ServiceCard count={"2"} text={"Full Stack projects"} />
      <ServiceCard count={"10+"} text={"Small projects"} />
      <ServiceCard count={"2"} text={"Discord Bots"} />
      <ServiceCard count={"15+"} text={"Live Wallpapers"} />
    </div>
  );
};

export default ServiceCount;
