import React from "react";

const SkillCard = ({ skills }) => {
  return (
    <div className="border border-[rgba(255,255,255,0.3)] rounded-md px-5 items-center space-x-4" style={{ boxShadow: "inset 0 0 10px rgba(255,255,255,0.3)" }}>
      <p className="text-base text-white">{skills}</p>
      <div className="w-16 h-12 rounded-md relative overflow-hidden"></div>
    </div>
  );
};

export default SkillCard;
